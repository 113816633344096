<template>
  <div class="todo-app-list">
    <ekle-headers
      :title="'Dil Seçenekleri'"
      :baslik="form.urun_adi"
      @mqShallShowLeftSidebar="$emit('mqShallShowLeftSidebar', $event)"
      @removeProduct="$emit('removeProduct', $event)"
    />
    <vue-perfect-scrollbar :settings="scrollbarSettings" class="todo-task-list-wrapper scroll-area p-2">
      <b-card-normal :title="'Sundugunuz rehberden bahsedin.'">
        <template v-slot:body>
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
              <b-row>
                <b-col cols="12">
                  <h5>Şahıs rehber veya sesli/yazılı rehber sunuyor musunuz?</h5>
                </b-col>
                <b-col cols="12">
                  <div class="demo-inline-spacing pb-3">
                    <b-form-radio v-model="form.rehber_sesli_yazili" name="rehber_sesli_yazili" :value="true">
                      Evet
                    </b-form-radio>
                    <b-form-radio v-model="form.rehber_sesli_yazili" name="rehber_sesli_yazili" :value="false">
                      Hayır
                    </b-form-radio>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <h5>Rehberleriniz lisanslı/sertifikalı mı?</h5>
                </b-col>
                <b-col cols="12">
                  <div class="demo-inline-spacing pb-3">
                    <b-form-radio v-model="form.rehber_lisanslimi" name="rehber_lisanslimi" :value="true"> Evet </b-form-radio>
                    <b-form-radio v-model="form.rehber_lisanslimi" name="rehber_lisanslimi" :value="false"> Hayır </b-form-radio>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <h5>Rehberleriniz şoför olarak da hizmet veriyor mu?</h5>
                </b-col>
                <b-col cols="12">
                  <div class="demo-inline-spacing pb-3">
                    <b-form-radio v-model="form.rehber_sofor_hizmeti" name="rehber_sofor_hizmeti" :value="true">
                      Evet
                    </b-form-radio>
                    <b-form-radio v-model="form.rehber_sofor_hizmeti" name="rehber_sofor_hizmeti" :value="false">
                      Hayır
                    </b-form-radio>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <h5>Dahil edilen rehber türü nedir?</h5>
                </b-col>
                <b-col cols="12">
                  <table class="table">
                    <thead>
                      <tr>
                        <th width="40%">Diller</th>
                        <th>Şahıs</th>
                        <th>Sesli</th>
                        <th>Okuma</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(dil, i) in form.rehber_bildigi_diller" :key="i">
                        <td>{{ dil.diller }}</td>
                        <td>
                          <b-form-checkbox v-model="form.rehber_bildigi_diller[i].sahis" class="custom-control-primary" />
                        </td>
                        <td>
                          <b-form-checkbox v-model="form.rehber_bildigi_diller[i].sesli" class="custom-control-primary" />
                        </td>
                        <td>
                          <b-form-checkbox v-model="form.rehber_bildigi_diller[i].okuma" class="custom-control-primary" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </b-col>
              </b-row>
              <b-row class="pt-1">
                <b-col cols="12" md="6" lg="6">
                  <b-button squared block type="submit" variant="primary">
                    <feather-icon icon="CheckSquareIcon" class="mr-50" />
                    <span class="align-middle">{{ form._id == null ? 'Kaydet ve Devam et' : 'Güncelle' }}</span>
                  </b-button>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                  <b-button squared block>
                    <feather-icon icon="XSquareIcon" class="mr-50" />
                    <span class="align-middle">İptal</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </template>
      </b-card-normal>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import EkleHeaders from '../component/ekleHeader.vue';
import BCardNormal from '@core/components/b-card-actions/BCardNormal.vue';
export default {
  components: {
    draggable,
    VuePerfectScrollbar,
    EkleHeaders,
    BCardNormal,
  },
  props: {
    form: {
      type: Object,
      required: true,
      default: {},
    },
    scrollbarSettings: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    show: false,
  }),

  methods: {
    onSubmit() {
      this.$emit('onSubmit');
    },
  },
};
</script>
<style lang="scss"></style>
